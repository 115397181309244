import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/Layouts/layout';
import SEO from '../components/seo';

const GlossaryPage = () => {
	const intl = useIntl();
	var glossaryArr = [
		{
			id: 'agreePS',
			term: <FormattedMessage id='glossaryTerms.agreeTerm' />,
			definition: <FormattedMessage id='glossaryTerms.agreeDef' />
		},
		{
			id: 'adminPenalty',
			term: <FormattedMessage id='glossaryTerms.ampTerm' />,
			definition: <FormattedMessage id='glossaryTerms.ampDef' />
		},
		{
			id: 'applicant',
			term: <FormattedMessage id='glossaryTerms.appTerm' />,
			definition: <FormattedMessage id='glossaryTerms.appDef' />
		},
		{
			id: 'builder',
			term: <FormattedMessage id='glossaryTerms.builderTerm' />,
			definition: <FormattedMessage id='glossaryTerms.builderDef' />
		},
		{
			id: 'deposit',
			term: <FormattedMessage id='glossaryTerms.depositTerm' />,
			definition: <FormattedMessage id='glossaryTerms.depositDef' />
		},
		{
			id: 'director',
			term: <FormattedMessage id='glossaryTerms.dirTerm' />,
			definition: <FormattedMessage id='glossaryTerms.dirDef' />
		},
		{
			id: 'expiryDate',
			term: <FormattedMessage id='glossaryTerms.expTerm' />,
			definition: <FormattedMessage id='glossaryTerms.expDef' />
		},
		{
			id: 'illegalBuilding',
			term: <FormattedMessage id='glossaryTerms.illegalTerm' />,
			definition: <FormattedMessage id='glossaryTerms.illegalDef' />
		},
		{
			id: 'interestedPerson',
			term: <FormattedMessage id='glossaryTerms.ipTerm' />,
			definition: <FormattedMessage id='glossaryTerms.ipDef' />
		},
		{
			id: 'interimOccupancy',
			term: <FormattedMessage id='glossaryTerms.interimTerm' />,
			definition: <FormattedMessage id='glossaryTerms.interimDef' />
		},
		{
			id: 'licensee',
			term: <FormattedMessage id='glossaryTerms.licenseeTerm' />,
			definition: <FormattedMessage id='glossaryTerms.licenseeDef' />
		},
		{
			id: 'newHome',
			term: <FormattedMessage id='glossaryTerms.nhTerm' />,
			definition: <FormattedMessage id='glossaryTerms.nhDef' />
		},
		{
			id: 'noticeOfProposal',
			term: <FormattedMessage id='glossaryTerms.nopTerm' />,
			definition: <FormattedMessage id='glossaryTerms.nopDef' />
		},
		{
			id: 'officer',
			term: <FormattedMessage id='glossaryTerms.offTerm' />,
			definition: <FormattedMessage id='glossaryTerms.offDef' />
		},
		{
			id: 'owner',
			term: <FormattedMessage id='glossaryTerms.ownerTerm' />,
			definition: <FormattedMessage id='glossaryTerms.ownerDef' />
		},
		{
			id: 'ownerBuilder',
			term: <FormattedMessage id='glossaryTerms.obTerm' />,
			definition: <FormattedMessage id='glossaryTerms.obDef' />
		},
		{
			id: 'partner',
			term: <FormattedMessage id='glossaryTerms.partnerTerm' />,
			definition: <FormattedMessage id='glossaryTerms.partnerDef' />
		},
		{
			id: 'preDeliveryInspection',
			term: <FormattedMessage id='glossaryTerms.pdiTerm' />,
			definition: <FormattedMessage id='glossaryTerms.pdiDef' />
		},
		{
			id: 'principal',
			term: <FormattedMessage id='glossaryTerms.principalTerm' />,
			definition: <FormattedMessage id='glossaryTerms.principalDef' />
		},
		{
			id: 'renewalDueDate',
			term: <FormattedMessage id='glossaryTerms.renewTerm' />,
			definition: <FormattedMessage id='glossaryTerms.renewDef' />
		},
		{
			id: 'umbrella',
			term: <FormattedMessage id='glossaryTerms.umbrellaTerm' />,
			definition: <FormattedMessage id='glossaryTerms.umbrellaDef' />
		},

		{
			id: 'vendor',
			term: <FormattedMessage id='glossaryTerms.vendorTerm' />,
			definition: <FormattedMessage id='glossaryTerms.vendorDef' />
		},
		{
			id: 'vendorBuilder',
			term: <FormattedMessage id='glossaryTerms.vbTerm' />,
			definition: <FormattedMessage id='glossaryTerms.vbDef' />
		},
		{
			id: 'warrantyInfoSheet',
			term: <FormattedMessage id='glossaryTerms.wisTerm' />,
			definition: <FormattedMessage id='glossaryTerms.wisDef' />
		}
	];

	return (
		<Layout header={<FormattedMessage id='footer.glossary' />}>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'footer.glossary' })} />
			<Container>
				<Row>
					<Col md={12}>
						<ul className='sitemapList'>
							{glossaryArr.map((link, i) => (
								<li id={link.id} key={link.id} className='definition'>
									<span>{link.term}</span>
									<span>{link.definition}</span>
								</li>
							))}
						</ul>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default GlossaryPage;
